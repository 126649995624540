// Entry point for the build script in your package.json

// if there is a data-confirm message on a link or button, then inject a confirm prompt of that message before continuing
$('a, button').on('click', function(ev) {
    if ($(this).data('no-loading') !== undefined) {
        return true;
    }

    showLoading();
    let msg = $(this).data('confirm');
    if (msg) {
        let result = confirm(msg);
        if (result == false) { hideLoading(); }
        return result;
    }
    return true;
})